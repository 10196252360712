import { events } from "@vzmi/types-wafer";
import { Sidekick as Config } from "../types/configs";
import { Viewer as ViewerConfig } from "../types/configs";
import { NavigationPayload, PartnerData, Plugin } from "../types/typings";
import { get } from "../utils";

/**
 * After the modal has changed content, we want to show/update sidekick
 * for that new article
 */
export default class Sidekick implements Plugin {
  private count: string;
  private partnerData!: PartnerData[];
  private sidekickUrl: URL;

  constructor(config: Config) {
    this.count = "12";
    this.sidekickUrl = new URL(config.origin);
    this.sidekickUrl.pathname = config.pathname;
  }

  public init(viewerConfig: ViewerConfig) {
    if (viewerConfig?.context?.site === "news") {
      this.sidekickUrl = new URL("https://news.yahoo.com");
      this.sidekickUrl.pathname = "/caas/sidekick/sidekick";
    }
    const initSidekick = this.initSidekick.bind(this);
    window.wafer.on("caas:article:init", initSidekick);
  }

  /**
   * When the content changes, we check the incoming articles
   * types to determine how many sidekick items to load
   * @param payload NavigationPayload
   */
  public async onContentChange(payload: NavigationPayload) {
    // reset sidekick for every content change
    window.wafer.state = {
      sidekick: "wf-state-reset"
    };
    const { cluster, uuid } = payload;
    const uuids = [uuid];
    for (const item of cluster) {
      if (item.uuid) {
        uuids.push(item.uuid);
      }
    }

    this.partnerData = await window.wafer.caas.getData(uuids);
    const longArticles = this.partnerData.filter(this.isLongArticle);
    this.count = longArticles.length > 1 ? "12" : "6";
  }

  public onViewerClosed() {
    window.wafer.state = {
      sidekick: "wf-state-reset"
    };
  }

  /**
   * Per the spec, if we have "long" articles which are defined as
   * an article which is hosted and not slideshow or video we will
   * load more sidekick items than if we have at least two short ones.
   */
  private isLongArticle(partnerData: PartnerData) {
    return (
      partnerData.hostedType === "hosted" &&
      partnerData.contentType !== "slideshow" &&
      partnerData.contentType !== "video"
    );
  }

  private initSidekick(event: events.caas.article.init.data) {
    const { adMeta, spaceId, uuid, ycts, wikiids } = event.meta
      .data as PartnerData;
    const property = this.partnerData
      .map(article => get(article, "adMeta.site") || "")
      .join(",");
    const uuids = [uuid];
    const siteAttribute = get(adMeta, "site_attribute") || "";

    for (const item of this.partnerData) {
      if (item.uuid && uuids.indexOf(item.uuid) === -1) {
        uuids.push(item.uuid);
      }
    }

    this.sidekickUrl.search = new URLSearchParams({
      count: this.count,
      property,
      siteAttribute,
      spaceId,
      uuid: uuids.join(","),
      wikis: wikiids,
      ycts
    }).toString();
    window.wafer.state = {
      sidekick: this.sidekickUrl.toString()
    };
  }
}
