/* istanbul ignore file until smartphone has YAFT */
import { Viewer as ViewerConfig } from "../types/configs";
import { Plugin } from "../types/typings";

/**
 * AFT2 is instrumented for both the navigation from the index page to the veiwer
 * as well as the return from the viewer to the index page
 */
export default class AFT2 implements Plugin {
  private pageContainer: string;
  private viewerContainer: string;
  private aft2MaxWaitTime: number;

  constructor() {
    this.pageContainer = "atomic";
    this.viewerContainer = "viewer";
    this.aft2MaxWaitTime = 6000;
  }

  public init(viewerConfig: ViewerConfig) {
    if (!viewerConfig?.yaft) {
      return;
    }

    if (viewerConfig.yaft.pageContainer) {
      this.pageContainer = viewerConfig.yaft.pageContainer;
    }

    if (viewerConfig.yaft.viewerContainer) {
      this.viewerContainer = viewerConfig.yaft.viewerContainer;
    }

    if (viewerConfig.yaft.aft2MaxWaitTime) {
      this.aft2MaxWaitTime = viewerConfig.yaft.aft2MaxWaitTime;
    }
  }

  public onViewerOpened() {
    this.onTransitionStart(this.viewerContainer);
  }

  public onContentChange() {
    this.onTransitionRenderStart();
  }

  public onViewerClosed() {
    this.onTransitionStart(this.pageContainer);
  }

  public onViewerDidClose() {
    this.onTransitionRenderStart();
  }

  private onTransitionStart(modulesAft2Container: string) {
    const { YAFT } = window;
    if (YAFT && YAFT.AFT2) {
      YAFT.AFT2.start();
      YAFT.updateConfig({
        maxWaitTime: this.aft2MaxWaitTime,
        modulesAft2Container
      });
      YAFT.adPosLoadTimes = [];
    }
  }

  private onTransitionRenderStart() {
    const { rapidInstance, YAFT } = window;
    if (rapidInstance && YAFT && YAFT.AFT2) {
      YAFT.AFT2.end((data, err) => {
        if (err) {
          return;
        }

        const aft2 = Math.round(data.aft);
        const vic2 = data.visuallyComplete;
        const srt2 = Math.round(data.startRender);

        const afterPageLoad = {
          AFT: aft2,
          AFT2: aft2,
          STR: srt2,
          VIC: vic2
        };
        const perfData = {
          perf_commontime: { afterPageLoad }
        };
        rapidInstance.beaconPerformanceData(perfData);
      });
    }
  }
}
