import { Config as RapidConfig } from "@vzmi/types-rapid";
import { events } from "@vzmi/types-wafer";
import { Rapid as Config } from "../types/configs";
import { NavigationPayload } from "../types/typings";
import { getDomainName } from "../utils";

import {
  CaaSLinkPayload,
  OnClickEvent,
  PartnerData,
  Plugin,
  SlideshowPayload
} from "../types/typings";
import { clone } from "../utils";

export default class Rapid implements Plugin {
  private config: Config;
  private homeUrl: string;
  private articleUrl: string;
  private refererUrl: string;

  constructor(config: Config) {
    this.articleUrl = "";
    this.refererUrl = "";
    this.config = config;
    this.homeUrl = window.location.href;
  }

  public init() {
    window.wafer.on("caas:article:inview", this.beacon.bind(this));
  }

  public onClick(event: OnClickEvent) {
    if (event.type === "slideshow") {
      this.onSlideshowClick(event);
    } else if (event.type === "link") {
      this.onLinkClick(event);
    }
  }

  /**
   * Triggered when viewer closed and finished navigate back to home
   */
  public async onViewerClosed() {
    if (!window.rapidInstance) {
      return;
    }

    const rapidConfig: RapidConfig = clone(window.rapidPageConfig.rapidConfig);
    const pageviewParams = Object.assign(rapidConfig.keys, {
      _R: this.articleUrl,
      _w: this.homeUrl,
      navtype: "client",
      pt: "home"
      // we are missing ss_cid (cluster id) that needs to be added from stream
    });

    // beacon the close interaction beacon with dwell stop option
    window.rapidInstance.beaconClick(
      "hl-viewer",
      "close",
      0,
      {
        elm: "btn",
        elmt: "cl",
        itc: 0,
        rspns: "cl",
        tar: getDomainName(this.homeUrl) // this should be base page's url domain
      },
      "",
      undefined,
      {
        dwell: "stop"
      }
    );
    // this will allow us to reset the rapid config before going back to homepage including the spaceid
    if (window.YAHOO && window.YAHOO.i13n) {
      window.YAHOO.i13n.SPACEID = rapidConfig.spaceid;
    }
    window.rapidInstance.reInit(rapidConfig);
    window.rapidInstance.beaconPageview(pageviewParams);
  }

  public async onContentWillChange(payload: NavigationPayload) {
    this.refererUrl = payload.refererUrl;
  }

  private getArticlePCpos(elem: Element) {
    const nodeList = elem.parentElement!.querySelectorAll(
      "div.wafer-caas,div.wafer-caas-complete"
    );
    const articles = Array.from(nodeList);
    return articles.findIndex(article => article === elem) + 1;
  }

  private async beacon(data: events.caas.article.init.data) {
    // this is necessary in between firing the pageviews when scrolling down viewer
    await this.beaconDwellStop();
    this.beaconPageView(data);
  }

  private beaconPageView(event: events.caas.article.init.data) {
    if (!window.rapidInstance) {
      return;
    }

    const {
      elem,
      meta: { data }
    } = event;

    const id = elem.id;
    const {
      contentType,
      homepageUrl,
      hostedType,
      publisher,
      spaceId,
      url,
      uuid
    } = data as PartnerData;
    // as we paginate through modal this url needs to be updated so we can properly track the referer when modal closes
    this.articleUrl = homepageUrl || url;
    // Clone the rapid config before making any additions / modifications
    const rapidConfig: RapidConfig = clone(window.rapidPageConfig.rapidConfig);
    rapidConfig.spaceid = parseInt(spaceId, 10);

    Object.assign(rapidConfig.keys, {
      _R: this.refererUrl || this.homeUrl,
      _w: this.articleUrl,
      navtype: "client",
      p_cpos: this.getArticlePCpos(event.elem),
      p_hosted: hostedType === "hosted" ? "onnet" : "offnet",
      pcp: publisher,
      pct: contentType,
      pd: "modal",
      // prefcnt is the number of articles loaded in the modal,
      prefcnt: this.config.clusterSize + 1,
      pstaid: uuid,
      pt: "content",
      rspns: "nav",
      slk: event.meta.data.title
    });
    if (window.YAHOO && window.YAHOO.i13n) {
      window.YAHOO.i13n.SPACEID = rapidConfig.spaceid;
    }
    window.rapidInstance.reInit(rapidConfig);
    if (window.rapidInstance.isModuleTracked(id)) {
      window.rapidInstance.refreshModule(id, true, true);
    } else {
      window.rapidInstance.addModules(id, true, true);
    }
  }

  private async beaconDwellStop() {
    return new Promise(resolve => {
      if (!window.rapidInstance) {
        return;
      }

      window.rapidInstance.beaconClick("rapidDwell", "", 0, {}, "", resolve, {
        dwell: "stop"
      });
    });
  }

  /**
   * Triggered when the previous or next button is hit on a slideshow carousel
   * @param event OnClickEvent
   */
  private onSlideshowClick(event: OnClickEvent) {
    if (!window.rapidInstance) {
      return;
    }

    // Beacon the click interaction
    const { pos, ylk } = event.payload as SlideshowPayload;
    window.rapidInstance.beaconClick(
      "sshow",
      ylk.slk,
      pos,
      ylk,
      ylk.outcm,
      undefined,
      { dwell: "stop" }
    );

    // Beacon the page view
    // Note that we've already set the rapid config when the article was viewed
    window.rapidInstance.beaconPageview();
  }

  /**
   * Triggered when the links with article are clicked
   * @param event OnClickEvent
   */
  private onLinkClick(event: OnClickEvent) {
    if (!window.rapidInstance) {
      return;
    }

    // Beacon the click interaction
    const { pos, ylk } = event.payload as CaaSLinkPayload;
    // to ensure we have parity, assigning hl-viewer as sec
    window.rapidInstance.beaconClick("hl-viewer", ylk.slk, pos, ylk, ylk.outcm);
  }
}
