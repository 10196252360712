import * as events from "./events";
import { init } from "./events";
import { Viewer as ViewerConfig } from "./types/configs";
import { Event, Handler, Plugin } from "./types/typings";

/**
 * This base class provides a platform from which different plugins
 * can communicate via lifecycle events.  This can be reused by variations
 * on the viewer which need to add/replace/delete any number of plugins.
 */
export default class Base {
  public handlers: Record<string, Handler[]>;

  constructor(plugins: Plugin[]) {
    this.handlers = {};
    const dispatch = this.dispatch.bind(this);
    plugins.forEach(plugin => (plugin.dispatch = dispatch));
    this.mapPluginHandlers(plugins);
  }

  /**
   * Map the events from all the plugins to the bound handler methods
   * on the plugin
   * @param {Plugin[]} plugins Plugin instances
   * @return {Object} Map of events to an array of plugin handles
   */
  public mapPluginHandlers(plugins: Plugin[]) {
    for (const event in events) {
      if (event in events) {
        this.handlers[event] = [];
        for (const plugin of plugins) {
          if (event in plugin) {
            const handler = plugin[event as Event]!.bind(plugin) as Handler;
            this.handlers[event].push(handler);
          }
        }
      }
    }
  }

  /**
   * Initialize each plugin
   */
  public init(config: ViewerConfig) {
    this.dispatch(init, config);
  }

  /**
   * Dispatch a lifecycle event along with an optional payload.
   * Plugins may listen for these lifecycle events and perform an action
   * optionally returning a Promise if the action is async
   * @param {String} event The lifecycle event
   * @param  {...any} args Payload passed to the dispatch event
   * @returns {Promise} Promise.all from all handlers of this event
   */
  public dispatch(event: string, ...args: any): Promise<any[]> {
    const promises = [];
    for (const handler of this.handlers[event]) {
      const promise = handler(...args);
      if (promise instanceof Promise) {
        promises.push(promise);
      }
    }
    // Return all promises in case a plugin needs to wait for
    // any fallout from the dispatched event to complete before
    // continuing.  Example: we wait for content to load before
    // continuing past the event to display modal content.
    return Promise.all(promises);
  }
}

/* istanbul ignore next */
if (__DEV__) {
  // Monkey patch the mapPluginHandlers function to display
  // a table of which plugins handle which events.
  const mapPluginHandlers = Base.prototype.mapPluginHandlers;
  Base.prototype.mapPluginHandlers = function(plugins: Plugin[]) {
    const table: Record<string, string[]> = {};
    for (const event in events) {
      if (event in events) {
        table[event] = [];
        for (const plugin of plugins) {
          if (event in plugin) {
            table[event].push(plugin.constructor.name);
          }
        }
      }
    }
    console.table(table);
    return mapPluginHandlers.call(this, plugins);
  };

  // Monkey patch the dispatch function to display timings.
  const dispatch = Base.prototype.dispatch;
  Base.prototype.dispatch = function(event: string, ...args: any) {
    const timer = `VIEWER:${event}`;
    console.time(timer);
    const promise = dispatch.call(this, event, ...args);
    promise.then(() => console.timeEnd(timer));
    return promise;
  };
}
