import { Sticky as Config } from "../types/configs";
import { Plugin } from "../types/typings";
import { debounce } from "../utils";

export default class Sticky implements Plugin {
  private activeSticky: HTMLElement;
  private config: Config;
  private stickyBottom: number;
  private viewerContainerNode?: HTMLElement;

  constructor(config: Config) {
    this.config = config;
    const onScroll = this.onScroll.bind(this);
    if (typeof requestAnimationFrame === "function") {
      window.addEventListener("scroll", () => requestAnimationFrame(onScroll));
    } else {
      window.addEventListener("scroll", debounce(onScroll, 10));
    }
    this.activeSticky = this.getStickyNode(config.pageStickyNode);
    this.stickyBottom = config.pageStickyBottom;
    this.config.pageStickyNode = config.pageStickyNode;
    this.viewerContainerNode = undefined;
  }

  public onViewerOpened() {
    this.activeSticky = this.getStickyNode(this.config.viewerStickyNode);
    this.stickyBottom = this.config.viewerStickyBottom;
    this.viewerContainerNode = this.getStickyNode(
      this.config.viewerContainerNode
    );
  }

  public onContentDidChange() {
    this.activeSticky = this.getStickyNode(this.config.viewerStickyNode);
    this.stickyBottom = this.config.viewerStickyBottom;
    this.viewerContainerNode = this.getStickyNode(
      this.config.viewerContainerNode
    );
  }

  public onViewerClosed() {
    this.activeSticky = this.getStickyNode(this.config.pageStickyNode);
    this.stickyBottom = this.config.pageStickyBottom;
    this.viewerContainerNode = this.getStickyNode(
      this.config.viewerContainerNode
    );
  }

  public getStickyNode(target: string) {
    const node = document.querySelector(target) as HTMLElement;
    return node;
  }

  private onScroll() {
    if (window.pageYOffset > 0) {
      document.body.classList.add("has-scrolled");
    } else {
      document.body.classList.remove("has-scrolled");
    }
    /**
     * A sidekick becomes 'stickyflow' when the bottom of the viewerContainerNode
     * is less than window.innerheight(i.e when other modules keep appearing in the bottom)
     * To prevent sidekick from overlapping on modules below viewerContainerNode,
     * we keep increasing bottom value of the sidekick as we scroll.
     * A sidekick becomes 'sticky' when sidekick reaches it's end and it's not stickyflow.
     */
    if (this.activeSticky && this.activeSticky.parentElement) {
      const { bottom, height } = this.activeSticky.getBoundingClientRect();
      const viewerContainerBottom = this.viewerContainerNode
        ? this.viewerContainerNode.getBoundingClientRect().bottom
        : 0;
      const isSticky =
        this.activeSticky.style.position === "fixed" &&
        !this.activeSticky.parentElement.classList.contains("sticky-flow");
      // TODO: This is just a temp hack to know that sidekick did
      // initialize, but we will listen for an event in the future.
      if (height < window.innerHeight) {
        return;
      }
      if (
        this.viewerContainerNode &&
        viewerContainerBottom < window.innerHeight
      ) {
        const stickyNodeBottomVal =
          window.innerHeight - viewerContainerBottom + this.stickyBottom;
        // activeSticky bottom value keeps changing as we scroll, to prevent overlapping on bottom modules
        Object.assign(this.activeSticky.style, {
          bottom: stickyNodeBottomVal + "px",
          position: "fixed"
        });
        this.activeSticky.parentElement.classList.remove("sticky-active");
        this.activeSticky.parentElement.classList.add("sticky-flow");
      } else if (isSticky) {
        // TODO: we probably want to capture this.config.rightRailTop opposed to hardcode config
        if (
          window.pageYOffset <
          height - window.innerHeight + this.config.rightRailTop
        ) {
          Object.assign(this.activeSticky.style, {
            bottom: "",
            position: ""
          });
          this.activeSticky.parentElement.classList.remove("sticky-active");
        }
      } else if (bottom < window.innerHeight) {
        Object.assign(this.activeSticky.style, {
          bottom: this.stickyBottom + "px",
          position: "fixed"
        });
        this.activeSticky.parentElement.classList.add("sticky-active");
        this.activeSticky.parentElement.classList.remove("sticky-flow");
      }
    }
  }

  get rightRail() {
    const elements = document.getElementsByClassName("viewer-aside");
    const rightRail = elements[0] as HTMLElement;
    return rightRail;
  }
}
