import {
  onContentChange,
  onContentDidChange,
  onContentWillChange,
  onViewerClosed,
  onViewerDidClose,
  onViewerOpened
} from "../events";
import { Viewer as ViewerConfig } from "../types/configs";
import {
  Dispatch,
  NavigationPayload,
  OnClickEvent,
  Plugin
} from "../types/typings";
import { encodeQuery, getBeaconPath } from "../utils";

/**
 * The Router plugin manages history and state - determining
 * if we are navigating from index to article, article to article
 * or article to index
 */
export default class Router implements Plugin {
  public dispatch!: Dispatch;
  private payload!: NavigationPayload;
  private home: URL;
  private isViewerClosed: boolean;
  private canonicalEl?: Element | null;
  private canonicalElStartVal?: string | null;
  private site!: string;
  private infoBeaconUri!: string;

  constructor() {
    this.home = new URL(location.href);
    this.isViewerClosed = true;
  }

  public init(viewerConfig: ViewerConfig) {
    if (viewerConfig) {
      this.site = viewerConfig.context.site || "fp";
    }
    this.infoBeaconUri = getBeaconPath(this.site);
    const canonicalEl = document.querySelector('link[rel="canonical"]');
    if (canonicalEl) {
      this.canonicalEl = canonicalEl;
      this.canonicalElStartVal = canonicalEl.getAttribute("href");
    }
    window.addEventListener("popstate", this.onPopState.bind(this));
    window.wafer.on("caas:article:init", () => {
      this.dispatch(onContentDidChange, this.payload);
    });
    window.wafer.on("caas:article:inview", event => {
      const { data } = event.meta;

      // Try to pull the url from the payload saved from the stream item DOM element
      // The url in the stream item itself is more consistent and trustworthy since
      // it is processed by a utility to generate the correct relative url even if
      // editorial changes url whereas the caas url is not
      for (const article of [this.payload, ...this.payload.cluster]) {
        if (article.uuid === data.uuid && article.pathname) {
          this.replaceState(this.payload, article.pathname);
          return;
        }
      }

      const urlToUse = data.homepageUrl || data.url;
      this.replaceState(this.payload, urlToUse);
    });
    window.wafer.on("caas:article:error", event => {
      // @ts-ignore
      const { viewerMainArticleId } = window.wafer.base._state;
      const { url, uuid } = event.meta;
      const navigateFailure = uuid === viewerMainArticleId;
      const error = navigateFailure
        ? "navigate_failure"
        : "cluster_article_failure";
      const beacon = new Image();
      const query = encodeQuery({ error, url });
      beacon.src = `/${this.infoBeaconUri}?${query}`;
      if (navigateFailure) {
        setTimeout(() => {
          // dont deeplink if viewer is closed
          if (this.isViewerClosed) {
            return;
          }
          window.location.href = this.payload.pathname;
        }, 500);
      }
    });
    window.wafer.on("caas:sidekick:error", event => {
      const beacon = new Image();
      const query = encodeQuery({
        error: "sidekick_error",
        url: event.meta.url
      });
      beacon.src = `/${this.infoBeaconUri}?${query}`;
    });
  }

  public onClick(event: OnClickEvent) {
    switch (event.type) {
      case "article":
        const payload: NavigationPayload = event.payload!;
        this.navigateToArticle(payload);
        this.pushState(payload, payload.pathname);
        if (this.canonicalEl) {
          this.canonicalEl.setAttribute("href", location.href);
        }
        break;
      case "back":
        this.onHeaderBackButtonClicked();
        break;
      case "close":
      case "overlay":
        this.navigateHome();
        break;
    }
  }

  private async navigateToArticle(payload: NavigationPayload) {
    this.payload = payload;
    await this.dispatch(onContentWillChange, payload);

    if (this.isViewerClosed) {
      await this.dispatch(onViewerOpened, payload);
      this.isViewerClosed = false;
    }
    await this.dispatch(onContentChange, payload);
  }

  private onHeaderBackButtonClicked() {
    history.back();
  }

  private async navigateHome() {
    this.isViewerClosed = true;
    this.pushState({}, this.home.href);
    if (this.canonicalEl && this.canonicalElStartVal) {
      this.canonicalEl.setAttribute("href", this.canonicalElStartVal);
    }
    await this.dispatch(onViewerClosed);
    // We need to do this setImmediate hack since the DOM
    // needs to repaint before the viewer is actually closed.
    // otherwise setting the scroll position, etc doesn't work
    setTimeout(() => this.dispatch(onViewerDidClose), 0);
  }

  /**
   * Handle the popstate event, closing the modal if navigating to the index
   * or showing the next article for article to article navigation.
   */
  private async onPopState() {
    if (window.location.href === this.home.href) {
      await this.navigateHome();
    } else {
      this.navigateToArticle(window.history.state);
    }
  }

  /**
   * Return the new URL by appending few params passed to index(home) page.
   */
  private getHrefWithQueryParams(url: string) {
    const nextUrl = new URL(url, location.origin);
    const atwKVParam = this.home.searchParams.get("atwKV");
    if (atwKVParam) {
      nextUrl.searchParams.append("atwKV", atwKVParam);
    }
    return nextUrl.href;
  }

  /**
   * Push state taking care to maintain the original query params
   */
  private pushState(payload: any, next: string) {
    history.pushState(payload, "", this.getHrefWithQueryParams(next));
  }

  /**
   * Replace state taking care to maintain the original query params
   */
  private replaceState(payload: any, next: string) {
    history.replaceState(payload, "", this.getHrefWithQueryParams(next));
  }
}
