const properties = ["finance.yahoo.com", "news.yahoo.com", "sports.yahoo.com"];
const domains = ["www.yahoo.com"].concat(properties);

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export const clone = (obj: object) => JSON.parse(JSON.stringify(obj));

/**
 * Find a node with one or more given attributes, recursively searching up the DOM
 * until a suitable ancestor is found.
 * @param {Element} node The node to check
 * @param {Object} opts Attributes which the node must match
 * @param {String} [opts.className] Class name which must exist on the target node
 * @param {String} [opts.id] Id which must match the target node
 * @param {String} [opts.tagName] Tag name which must match the target node
 * @returns {Element} The node which matches the given attributes
 */
export const ancestor = (
  node: HTMLElement | null,
  {
    className,
    id,
    tagName
  }: { className?: string; id?: string; tagName?: string }
): HTMLElement | undefined => {
  if (!node) {
    return;
  }

  if (
    (className && !node.classList.contains(className)) ||
    (id && id !== node.id) ||
    (tagName && node.tagName !== tagName)
  ) {
    return ancestor(node.parentElement, { className, id, tagName });
  }

  return node;
};

/**
 * Return the pathname for an article
 * /entertainment/bar.html         => /entertainment/bar.html
 * https://news.yahoo.com/foo.html => /news/foo.html
 * @param {String} url Full or relative url for an article
 */
export const getPathname = (url: string) => {
  if (!url || url.startsWith("/")) {
    return url;
  }

  const { hostname, pathname } = new URL(url);
  if (properties.includes(hostname)) {
    // Handle news.yahoo.com => /news
    const [property] = hostname.split(".");
    return `/${property}${pathname}`;
  }

  return pathname;
};

/**
 * Return the hostname of a url
 * https://in.yahoo.com/?tsrc=1        => in.yahoo.com
 * @param {String} url Full or relative url
 */
export const getDomainName = (url: string) => {
  const { hostname } = new URL(url);
  return hostname || "";
};

export const viewerEligible = (node: HTMLElement) => {
  const href = node.getAttribute("href") || "";

  // Assume any relative paths are okay
  if (href.startsWith("/")) {
    return true;
  }

  const { hostname } = new URL(href);
  return domains.includes(hostname);
};

export function encodeQuery(params: Record<string, string>): string {
  const query = new URLSearchParams();
  for (const param in params) {
    if (param in params) {
      query.append(param, params[param]);
    }
  }
  return query.toString();
}

export function getBeaconPath(site: string) {
  let beaconPath = "p.gif";
  if (site === "news") {
    beaconPath = "_td_api/beacon/error";
  }
  return beaconPath;
}

export const getUuid = (node: HTMLElement | null): string | void => {
  if (!node) {
    return;
  }

  if (node.hasAttribute("data-uuid")) {
    return node.getAttribute("data-uuid")!;
  }

  if (node.hasAttribute("data-wf-caas-uuid")) {
    return node.getAttribute("data-wf-caas-uuid")!;
  }

  return getUuid(node.parentElement);
};

/**
 * A debounced function will be called once initially, and then a second
 * at then end of any number of subsequent requests that occur with at
 * most `ms` milliseconds between each call.
 * @param {Function} fn Function to debounce
 * @param {Number} ms Milliseconds to wait between subsequent requests
 * @returns {Function} Debounced function
 */
export const debounce = (
  fn: (...args: any) => any,
  ms: number
): ((...args: any) => any) => {
  let debounced = false;
  let timeout: number;
  return (...args) => {
    if (!timeout) {
      // Call the function as soon as it's invoked the first time
      fn(...args);
    } else {
      // Otherwise flag for an invocation for the end of the period
      debounced = true;
      clearTimeout(timeout);
    }

    timeout = window.setTimeout(() => {
      if (debounced) {
        fn(...args);
      }
      debounced = false;
    }, ms);
  };
};

/**
 * Retrieves the sub value at the provided path from the value object provided
 * Basically a copy of get_obj_value but with less guardrails since we have TS
 * @param obj The object from which to extract the property value
 * @param path A '.' delimited string specifying the object traversal path
 */
export const get = (obj: any, path: string): any => {
  let value = obj;
  for (const key of path.split(".")) {
    if (!value || !value.hasOwnProperty(key)) {
      return;
    }
    value = value[key];
  }
  return value;
};

export const waferCaasLoaded = () => !!get(window, "wafer.wafers.wafer-caas");
