import { NavigationPayload, Plugin } from "../types/typings";

/**
 * The ScrollRestoration plugin restores the scroll
 * position when navigating to and from article pages
 */
export default class ScrollRestoration implements Plugin {
  private scrollHistory: Record<string, number>;

  constructor() {
    this.scrollHistory = {};
  }

  public onContentWillChange() {
    const { pathname } = location;
    this.scrollHistory[pathname] = window.pageYOffset;
  }

  public onContentChange(payload: NavigationPayload) {
    this.restoreScrollPosition(payload.pathname);
  }

  public onViewerClosed() {
    const { pathname } = location;
    this.restoreScrollPosition(pathname);
  }

  private restoreScrollPosition(pathname: string) {
    const pageYOffset = this.scrollHistory[pathname] || 0;
    window.scrollTo(0, pageYOffset);
  }
}
