/* istanbul ignore file */
/**
 * Content to search is module that shows related search terms based on article uuid.
 * The related search terms will also include revenue terms.
 * This was generating good revenue for finance articles. So restrited this module to only finance.
 * Since this uses iframe page has to inform the iframe when module is in view. We use post-message to inform iframe.
 * PRD: https://docs.google.com/document/d/1U48Jq57lpTZ0868bhxopgrzv2GsCYqtMHVceWanWA98/edit
 */

import { events } from "@vzmi/types-wafer";
import { Ads as Config } from "../types/configs";
import { Viewer as ViewerConfig } from "../types/configs";
import { C2sPostMessageData, Plugin } from "../types/typings";
import { debounce, getBeaconPath } from "../utils";

export default class Content2Search implements Plugin {
  private config?: Config;
  private home: URL;
  private c2sSessionId: string;
  private c2sArticleIndex: number;
  private c2sElements: HTMLIFrameElement[];
  private onScroll: any;
  private site!: string;
  private infoBeaconUri!: string;

  constructor() {
    this.home = new URL(location.href);
    this.c2sSessionId = "";
    this.c2sArticleIndex = 0;
    this.c2sElements = [];
    this.onScroll = null;
  }

  public init(viewerConfig: ViewerConfig) {
    if (viewerConfig) {
      this.site = viewerConfig.context.site || "fp";
    }
    this.infoBeaconUri = getBeaconPath(this.site);
    if (window.adsConfig) {
      this.config = window.adsConfig;
    }
    if (
      this.config &&
      this.config.viewerPositionMeta &&
      this.config.viewerPositionMeta.c2siframe
    ) {
      window.wafer.on("caas:article:init", this.articleInit.bind(this));
      window.wafer.on("caas:article:inview", this.articleInview.bind(this));
    }
  }

  public onViewerClosed() {
    if (this.onScroll) {
      window.removeEventListener("scroll", this.onScroll);
      this.onScroll = null;
    }
  }

  public articleInit(event: events.caas.article.init.data) {
    if (this.config!.viewerPositionMeta!.c2siframe) {
      if (
        this.config!.viewerPositionMeta!.c2siframe.sites.indexOf(
          event.meta.data.adMeta.site
        ) >= 0
      ) {
        this.c2sSessionId =
          Date.now() +
          "_" +
          Math.random()
            .toString()
            .substr(2, 5);
        this.c2sArticleIndex = 0;
        this.c2sElements = [];
      }
    }
  }

  public articleInview(event: events.caas.article.init.data) {
    this.c2sArticleIndex++;
    // we render this module for all articles only in func tests
    const allowForAllSites =
      this.home.host === "qa5.www.yahoo.com" &&
      this.home.searchParams.has("c2sallowall");
    if (
      event.elem &&
      event.elem.querySelector &&
      event.elem.querySelector(".js-c2smodule")
    ) {
      // module is already present dont render it again
      return;
    }
    if (this.config!.viewerPositionMeta!.c2siframe) {
      if (
        allowForAllSites ||
        (event.meta &&
          event.meta.data &&
          event.meta.data.adMeta.site &&
          this.config!.viewerPositionMeta!.c2siframe.sites.indexOf(
            event.meta.data.adMeta.site
          ) >= 0)
      ) {
        this.loadC2siframe(event);
      }
    }
  }

  public loadC2siframe(event: events.caas.article.init.data) {
    const caasBodyEl = event.elem.querySelector(".caas-body");
    if (caasBodyEl) {
      const iframeEl = document.createElement("iframe");
      iframeEl.setAttribute("class", "js-c2smodule");
      iframeEl.setAttribute("frameborder", "no");
      iframeEl.setAttribute("scrolling", "no");
      iframeEl.setAttribute("allowtransparency", "true");
      iframeEl.setAttribute("async", "true");
      iframeEl.setAttribute("tabindex", "-1");
      iframeEl.style.marginTop = "20px";
      iframeEl.style.width = "100%";
      const articleUuid = event.meta.data.uuid;
      const c2siframeUrl = new URL(
        this.config!.viewerPositionMeta!.c2siframe!.url
      );
      c2siframeUrl.searchParams.append("uuid", articleUuid);
      c2siframeUrl.searchParams.append("sid", String(this.c2sSessionId));
      c2siframeUrl.searchParams.append("iid", String(this.c2sArticleIndex));
      iframeEl.setAttribute("src", c2siframeUrl.href);
      const c2sStartTime = Date.now();
      // if iframe does not load within the timeout hide it
      const c2sTimer = setTimeout(() => {
        iframeEl.style.display = "none";
        window.wafer.utils.fireBeacon(
          "/" +
            this.infoBeaconUri +
            "?type=c2siframe-timedout&uuid=" +
            articleUuid,
          {
            useNavigator: false,
            useTimestamp: false
          }
        );
      }, this.config!.viewerPositionMeta!.c2siframe!.timeout || 5000);
      iframeEl.addEventListener(
        "load",
        this.handleOnload.bind(this, articleUuid, c2sTimer, c2sStartTime),
        false
      );
      window.addEventListener(
        "message",
        this.handlePostMessage.bind(this, articleUuid, iframeEl),
        false
      );
      caasBodyEl.appendChild(iframeEl);
      this.c2sElements.push(iframeEl);
      if (
        !this.onScroll &&
        iframeEl.contentWindow &&
        iframeEl.contentWindow.postMessage
      ) {
        if (typeof requestAnimationFrame === "function") {
          this.onScroll = () =>
            requestAnimationFrame(this.handleScroll.bind(this));
        } else {
          this.onScroll = debounce(this.handleScroll.bind(this), 10);
        }
        window.addEventListener("scroll", this.onScroll);
      }
    }
  }

  public handleOnload(
    articleUuid: string,
    c2sTimer: any,
    c2sStartTime: number
  ) {
    clearTimeout(c2sTimer);
    window.wafer.utils.fireBeacon(
      "/" +
        this.infoBeaconUri +
        "?type=c2siframe-loaded&uuid=" +
        articleUuid +
        "&latency=" +
        (Date.now() - c2sStartTime),
      {
        useNavigator: false,
        useTimestamp: false
      }
    );
  }
  public handlePostMessage(
    articleUuid: string,
    iframeEl: HTMLIFrameElement,
    e: MessageEvent
  ) {
    if (!articleUuid || !iframeEl) {
      return;
    }
    const msg = e as C2sPostMessageData;
    if (
      msg.data &&
      msg.data.event === "c2sDisplay" &&
      msg.data.articleId === articleUuid
    ) {
      const terms = msg.data.terms || [];
      if (msg.data.height) {
        iframeEl.style.height = msg.data.height + "px";
      }
      window.wafer.utils.fireBeacon(
        "/" +
          this.infoBeaconUri +
          "?type=c2siframe-renderd-searchitems&uuid=" +
          articleUuid +
          "&widget=" +
          escape(e.data.widget) +
          "&flavor=" +
          escape(e.data.flavor || "") +
          "&count=" +
          terms.length +
          "&terms=" +
          escape(JSON.stringify(terms)),
        {
          useNavigator: false,
          useTimestamp: false
        }
      );
    }
  }

  public handleScroll() {
    this.c2sElements.forEach(iframeEl => {
      if (
        // @ts-ignore
        window.wafer.utils.elementInView(
          iframeEl,
          {},
          // @ts-ignore
          window.wafer.base.viewport
        )
      ) {
        iframeEl!.contentWindow!.postMessage("c2s-node-visible", "*");
      } else {
        iframeEl!.contentWindow!.postMessage("c2s-node-not-visible", "*");
      }
    });
  }
}
