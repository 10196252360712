import { events } from "@vzmi/types-wafer";
import { Comscore as Config } from "../types/configs";
import { Viewer as ViewerConfig } from "../types/configs";
import { Plugin } from "../types/typings";

export default class Comscore implements Plugin {
  private config: Config;
  private comscoreVendorId = 77;
  private spaceId!: string;
  private c14!: string;

  constructor(config: Config) {
    this.config = config;
  }

  public init(viewerConfig: ViewerConfig) {
    if (!window.rapidInstance) {
      return;
    }

    this.c14 = window.YAHOO?.comscore?.c14 || "-1";
    this.spaceId = window.rapidInstance.getRapidAttribute("spaceid");
    const beaconPageView = this.beaconPageView.bind(this);
    window.wafer.on("caas:article:inview", beaconPageView);
  }

  public onViewerClosed() {
    if (!window.rapidInstance) {
      return;
    }

    const url = new URL(location.pathname, location.origin).toString();
    this.beaconComscore(this.spaceId, url);
  }

  private async beaconPageView(event: events.caas.article.init.data) {
    const { spaceId, url } = event.meta.data;
    this.beaconComscore(spaceId, url);
  }

  private async beaconComscore(spaceId: string, url: string) {
    const src = new URL(this.config.href);
    src.searchParams.set("c1", "2");
    src.searchParams.set("c14", this.c14);

    // https://git.ouroath.com/amp/amp-yahoo/pull/466#issuecomment-2182227
    src.searchParams.set("c2", "7241469");
    src.searchParams.set("c5", spaceId);
    src.searchParams.set("c7", encodeURIComponent(url));
    src.searchParams.set("ns__t", Date.now().toString());

    const [consent, doNotSell] = await Promise.all([
      this.getComscoreConsent(),
      this.getDoNotSell()
    ]);

    if (consent) {
      // If the call to __cmp.getVendorConsents indicates that gdpr applies and returns vendor consents, use that value
      src.searchParams.set("cs_ucfr", consent);
    } else if (doNotSell) {
      // Otherwise check the __uspapi.getDoNotSell value, if it applies
      src.searchParams.set("cs_ucfr", doNotSell);
    } else {
      src.searchParams.delete("cs_ucfr");
    }

    const beacon = new Image();
    beacon.src = src.toString();
  }

  private getConsentData(
    func: string,
    name: string,
    version: number
  ): Promise<any> {
    // @ts-ignore
    if (typeof window[func] !== "function") {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      // @ts-ignore
      window[func](name, version, (result, success) => {
        if (success) {
          resolve(result);
        } else {
          reject();
        }
      });
    });
  }

  private async getComscoreConsent(): Promise<string | void> {
    try {
      const { gdprApplies, vendorConsents } = await this.getConsentData(
        "__cmp",
        "getVendorConsents",
        1
      );
      if (gdprApplies && vendorConsents) {
        return vendorConsents[this.comscoreVendorId] ? "1" : "0";
      }
    } catch (e) {
      // do nothing
    }
  }

  private async getDoNotSell(): Promise<string | void> {
    try {
      const { doNotSell } = await this.getConsentData(
        "__uspapi",
        "getDoNotSell",
        1
      );
      if (typeof doNotSell === "boolean") {
        return doNotSell ? "0" : "1";
      }
    } catch (e) {
      // do nothing
    }
  }
}
