import { Beacon as Config } from "../types/configs";
import { Viewer as ViewerConfig } from "../types/configs";
import { OnClickEvent, Plugin } from "../types/typings";
import { encodeQuery, getBeaconPath } from "../utils";

export default class Beacon implements Plugin {
  private uuid!: string;
  private t0!: number;
  private t1!: number;
  private t2!: number;
  private t3!: number;
  private site!: string;
  private infoBeaconUri!: string;

  public init(viewerConfig: ViewerConfig) {
    if (viewerConfig) {
      this.site = viewerConfig.context.site || "fp";
    }
    this.infoBeaconUri = getBeaconPath(this.site);
    const beaconPageView = this.beaconPageView.bind(this);
    window.wafer.on("caas:article:inview", beaconPageView);
    window.wafer.on("caas:article:init", beaconPageView);
    // @ts-ignore
    window.wafer.on("log", this.beaconLog.bind(this));
    this.logCaasEvent("caas:prefetch:duplicate:id");
    this.logCaasEvent("caas:prefetch:failed");
    this.logCaasEvent("caas:prefetch:success");
    this.logCaasEvent("caas:set:indexeddb:failed");
    this.logCaasEvent("caas:set:remove:failed");
  }

  public onClick(event: OnClickEvent) {
    if (event.type === "article") {
      this.uuid = event.payload!.uuid;
      this.t0 = Date.now();
    }
  }

  public async onContentWillChange() {
    this.t1 = Date.now();
  }

  public async onContentChange() {
    this.t2 = Date.now();
  }

  public async onContentDidChange() {
    this.t3 = Date.now();
    this.beaconClk2Cnt();
  }

  public onViewerOpened() {
    this.beacon({
      beaconType: "homepage_viewer",
      info: "show_viewer"
    });
  }

  public onViewerClosed() {
    this.beacon({
      beaconType: "homepage_viewer",
      info: "close_viewer"
    });
  }

  private beaconLog({ meta, name, type }: any = {}) {
    if (name === "WaferCaas" || (name === "BrowserType" && meta.slow)) {
      const beaconUrl = `/${this.infoBeaconUri}?error=log&meta=${JSON.stringify(
        meta
      )}`;
      window.wafer.utils.fireBeacon(beaconUrl, {
        useNavigator: false,
        useTimestamp: false
      });
    }
  }

  private beaconPageView() {
    const beacon = new Image();
    const src = new URL("/pageview", window.location.origin);
    src.search = new URLSearchParams({
      // TODO: replace these with actual values
      bucket: "bucket",
      rid: "rid"
    }).toString();
    beacon.src = src.toString();
  }

  private beaconClk2Cnt() {
    this.beacon({
      code: "homepage_viewer_clk2cnt",
      t0: this.t0,
      t1_t0: Math.floor(this.t1 - this.t0),
      t2_t1: Math.floor(this.t2 - this.t1),
      t3_t2: Math.floor(this.t3 - this.t2),
      time: Math.floor(this.t3 - this.t0),
      uuid: this.uuid
    });
  }

  private beacon(params: any): void {
    const beacon = new Image();
    beacon.src = `/${this.infoBeaconUri}?${encodeQuery(params)}`;
  }

  private logCaasEvent = (
    event:
      | "caas:prefetch:duplicate:id"
      | "caas:prefetch:failed"
      | "caas:prefetch:success"
      | "caas:set:indexeddb:failed"
      | "caas:set:remove:failed"
  ) => {
    // @ts-ignore
    window.wafer.on(event, (data?: { meta?: any }) => {
      const meta = (data && data.meta) || {};
      const beaconUrl = `/${
        this.infoBeaconUri
      }?error=${event}&meta=${JSON.stringify(meta)}`;
      window.wafer.utils.fireBeacon(beaconUrl, {
        useNavigator: false,
        useTimestamp: false
      });
    });
  };
}
