/* istanbul ignore file */
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
(() => {
  // @ts-ignore
  if (typeof window.CustomEvent === "function") {
    return false;
  }

  function CustomEvent(event: string, params: any) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  // @ts-ignore
  CustomEvent.prototype = window.Event.prototype;
  // @ts-ignore
  window.CustomEvent = CustomEvent;
})();
