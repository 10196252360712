import { events } from "@vzmi/types-wafer";
import { Viewer as ViewerConfig } from "../types/configs";
import { NielsenInstance, NielsenMeta, Plugin } from "../types/typings";

export default class Nielsen implements Plugin {
  private meta?: NielsenMeta;
  private nSdkInstance?: NielsenInstance;
  private nSdkNseNo?: number;
  private trackedItemsInViewer: { [key: string]: boolean };

  constructor() {
    const nielsenSpaEvent = this.nielsenSpaEvent.bind(this);
    this.trackedItemsInViewer = {};
  }

  public init(viewerConfig: ViewerConfig) {
    if (viewerConfig) {
      this.meta = {
        assetid: window.location.pathname,
        section: "Yahoo!",
        type: "static"
      };
    }
    this.nSdkInstance = window.nSdkInstance;
    this.nSdkNseNo = window.nSdkNseNo;

    /** fires when viewe opens and scrolls to 2nd or 3rd articles */
    window.wafer.on(
      "caas:article:inview",
      (event: events.caas.article.init.data) => {
        if (
          event.meta &&
          event.meta.data &&
          event.meta.data.uuid &&
          !this.trackedItemsInViewer[event.meta.data.uuid]
        ) {
          this.nielsenSpaEvent("caas:article:inview");
          this.trackedItemsInViewer[event.meta.data.uuid] = true;
        }
      }
    );
  }

  public onViewerOpened() {
    this.nSdkNseNo = window.nSdkNseNo;
    this.trackedItemsInViewer = {};
  }

  public onViewerClosed() {
    this.trackedItemsInViewer = {};
    this.nielsenSpaEvent("viewerClosed");
  }

  private nielsenSpaEvent(trigger: string): void {
    if (this.nSdkInstance && this.nSdkNseNo !== undefined && this.meta) {
      this.nSdkInstance.ggPM("staticend", this.meta);
      this.nSdkNseNo = ++this.nSdkNseNo!;
      this.meta.referrerURL = this.meta.pageURL || window.location.href;
      this.meta.pageURL = "page_" + this.nSdkNseNo + "_" + window.location.href;
      this.nSdkInstance.ggPM("staticstart", this.meta);

      if (trigger === "viewerClosed") {
        window.nSdkNseNo = this.nSdkNseNo;
      }

      if (__DEV__) {
        console.log("VIEWER:NielsenSpaEvent:", trigger, this.meta);
      }
    }
  }
}
