import "../polyfills/DateNow";
import { NavigationPayload, Plugin } from "../types/typings";
import { sleep } from "../utils";

/**
 * This plugins manages the modal itself.  It is responsible for not only creating the modal,
 * but also showing, hiding, and updating the modal's content in response to change events.
 */
export default class Modal implements Plugin {
  private mainView!: HTMLElement;
  private viewer!: HTMLElement;
  private viewerParent!: HTMLElement;
  private listener!: (e: KeyboardEvent) => void;

  public init() {
    this.mainView =
      document.getElementById("Masterwrap")! ||
      document.getElementById("MainView")!;
    this.viewer = document.getElementById("content-viewer")!;
    this.viewerParent = document.getElementById("viewer")!;
    this.listener = e => {
      if (e.keyCode === 27) {
        history.back();
      }
    };
  }

  public onContentWillChange() {
    window.wafer.state = {
      showViewer: Date.now()
    };
  }

  public async onContentChange(payload: NavigationPayload) {
    const { cluster, expandComments, label, uuid } = payload;
    const viewerClusterArticleIds = cluster.map(c => c.uuid);
    const viewerMainArticleCollapsed = expandComments ? "1" : "0";
    this.viewer.classList.remove(...[0, 1, 2, 3, 4, 5].map(i => `cluster${i}`));
    this.viewer.classList.add(`cluster${cluster.length}`);

    await sleep(1);
    window.wafer.state = {
      viewerClusterArticleId1: viewerClusterArticleIds[0],
      viewerClusterArticleId2: viewerClusterArticleIds[1],
      viewerClusterArticleId3: viewerClusterArticleIds[2],
      viewerClusterArticleId4: viewerClusterArticleIds[3],
      viewerClusterArticleId5: viewerClusterArticleIds[4],
      viewerMainArticleCategoryLabel: label || "",
      viewerMainArticleCollapsed,
      viewerMainArticleId: uuid
    };
    await sleep(1);
  }

  public async onViewerOpened() {
    window.wafer.base.lock(this.mainView);
    const { top } = this.mainView.getBoundingClientRect();
    this.mainView.setAttribute("aria-hidden", "true");
    Object.assign(this.mainView.style, {
      position: "fixed",
      top: top + "px"
    });
    this.viewerParent.setAttribute("aria-hidden", "false");

    document.documentElement.classList.add("modal-open");
    // Wait for the viewer to open so wafer can calculate
    // the dimensions needed for slideshow
    await sleep(5);
    this.mainView.style.visibility = "hidden";
    window.addEventListener("keydown", this.listener);
  }

  public onViewerClosed() {
    this.viewerParent.setAttribute("aria-hidden", "true");
    this.mainView.style.visibility = "";
    this.viewer.classList.remove("wafer-template-success");
    // call destroy before removing nodes
    window.wafer.base.destroy(this.viewer);
    for (const child of this.viewer.children) {
      this.viewer.removeChild(child);
    }
    document.documentElement.classList.remove("modal-open");
    this.mainView.removeAttribute("aria-hidden");
    Object.assign(this.mainView.style, {
      position: "",
      top: ""
    });
    window.wafer.base.unlock(this.mainView);
    window.removeEventListener("keydown", this.listener);
    window.wafer.state = {
      showViewer: "wf-state-reset",
      viewerClusterArticleId1: "wf-state-reset",
      viewerClusterArticleId2: "wf-state-reset",
      viewerClusterArticleId3: "wf-state-reset",
      viewerClusterArticleId4: "wf-state-reset",
      viewerClusterArticleId5: "wf-state-reset",
      viewerMainArticleCategoryLabel: "",
      viewerMainArticleCollapsed: "wf-state-reset",
      viewerMainArticleId: "wf-state-reset"
    };
  }
}
