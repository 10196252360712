import { Plugin } from "../types/typings";

/**
 * Responsivle for updating the docuemtn title when the modal
 * is opened, closed, or content is otherwise changed.
 */
export default class Title implements Plugin {
  // The original page title, eg "Yahoo"
  private title: string;

  constructor() {
    this.title = document.title;
  }

  public init() {
    // When we open the viewer or scroll between articles, update the title.
    window.wafer.on("caas:article:inview", event => {
      document.title = event.meta.data.title;
    });
  }

  /**
   * When the viewer is closed, restore the document title to the
   * original value at the time of page load.
   */
  public onViewerClosed() {
    document.title = this.title;
  }
}
