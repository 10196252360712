import { events } from "@vzmi/types-wafer";
import { onClick } from "../events";
import { ClickHandler as Config } from "../types/configs";
import { Article, Dispatch, Plugin } from "../types/typings";
import { ancestor, getPathname, getUuid, waferCaasLoaded } from "../utils";

/**
 * This plugin listens for click events and dispatches the onArticleClicked
 * event IFF the node that was clicked is a viewer eligible link
 */
export default class ClickHandler implements Plugin {
  public dispatch!: Dispatch;
  private tagName: string;
  private className: string;
  private clusters: Article[];
  private clusterSize: number;
  private ntkClusterSize: number;

  constructor(config: Config) {
    this.tagName = config.targetTagName;
    this.className = config.targetClassName;
    this.clusters = [];
    this.clusterSize = config.clusterSize;
    this.ntkClusterSize = config.ntkClusterSize;
  }

  public init() {
    const handleClick = this.handleClick.bind(this);
    // const handleYahooLinkFetch = this.handleYahooLinkFetch.bind(this);
    const handleCaaSClickLink = this.handleCaaSClickLink.bind(this);

    document.body.addEventListener("click", handleClick);
    window.wafer.on("caas:link:clicked", handleCaaSClickLink);
    window.wafer.on("caas:yahooLink:clicked", handleCaaSClickLink);
    // caas:yahooLink:fetched is guaranteed to have uuid
    // window.wafer.on("caas:yahooLink:fetched", handleYahooLinkFetch);
  }

  public getArticles(node: HTMLElement, clickedUuid: string) {
    const articles: Record<string, Article> = {};
    const elements = node.querySelectorAll("[data-wf-caas-uuid]");

    // Iterate over each link in the cluster
    for (const el of elements) {
      const uuid = el.getAttribute("data-wf-caas-uuid");

      if (uuid === clickedUuid) {
        continue;
      }
      const href = el.getAttribute("href") || "";
      const pathname = getPathname(href);
      if (uuid && pathname) {
        articles[uuid] = { pathname, uuid };
      }
    }

    return articles;
  }

  public onViewerClosed() {
    this.clusters = [];
  }

  private getClusterSize(target: HTMLElement) {
    // Shortcut in case we really don't want clustering
    if (Math.max(this.clusterSize, this.ntkClusterSize) === 0) {
      return 0;
    }

    if (ancestor(target, { className: "ntktdv2" })) {
      return this.ntkClusterSize;
    }

    // Notification panel can have any number of items between 0-5 in the last 2 days.
    if (ancestor(target, { className: "yns-notifications" })) {
      return 0;
    }

    return this.clusterSize;
  }

  private handleCaaSClickLink(event: events.caas.yahooLinkClick.data) {
    const eventMetaData = event.meta.data;

    // handles click within the modal
    // TODO: we may want to fire off an event here for instrumentation
    if (eventMetaData.isComment) {
      const uuid = eventMetaData.params.g;
      return this.dispatch(onClick, { type: "comments", payload: { uuid } });
    }
    const { sec } = eventMetaData;
    if (eventMetaData.href) {
      const { pos, slk } = eventMetaData;
      this.dispatch(onClick, {
        payload: {
          pos,
          ylk: Object.assign({ sec, slk }, eventMetaData.params)
        },
        type: "link"
      });
      window.open(eventMetaData.href, eventMetaData.target)!.opener = null;
      return;
    }
    if (sec === "sshow") {
      const { pos, sec, slk } = eventMetaData;
      this.dispatch(onClick, {
        payload: {
          pos,
          ylk: Object.assign({ sec, slk }, eventMetaData.params)
        },
        type: "slideshow"
      });
    }
  }

  // private handleYahooLinkFetch(event: events.caas.yahooLinkFetched.data) {
  //   const eventMetaData = event.meta && event.meta.data;
  //   const metaUuid = eventMetaData && eventMetaData.uuid;
  //   const refererUrl = window.location.href; // use current href as referrer before navigate away to next article
  //   const parentUuid = eventMetaData.parentUuid;
  //   const payload = {
  //     cluster: this.clusters.filter(cluster => {
  //       return cluster.uuid !== parentUuid;
  //     }), // filter cluster articles to not include parent uuid
  //     expandComments: false,
  //     label: "",
  //     pathname: eventMetaData.href,
  //     refererUrl,
  //     uuid: metaUuid
  //   };
  //   this.dispatch(onClick, { type: "article", payload });
  // }

  private handleClick(event: any) {
    if (!waferCaasLoaded()) {
      return;
    }

    const target: HTMLElement = event.target;
    const id = target && target.id;

    if (ancestor(target, { id: "header-back-button", tagName: "A" })) {
      event.preventDefault();
      return this.dispatch(onClick, { type: "back" });
    }

    if (ancestor(target, { id: "closebtn", tagName: "BUTTON" })) {
      return this.dispatch(onClick, { type: "close" });
    }

    if (id === "Overlay") {
      return this.dispatch(onClick, { type: "overlay" });
    }

    const node = ancestor(target, {
      className: this.className,
      tagName: this.tagName
    });

    if (node) {
      // ctrl or cmd + left click should open a new tab
      if (event.ctrlKey || event.metaKey) {
        return;
      }
      if (typeof event.preventDefault === "function") {
        event.preventDefault();
      }
      const expandComments = !!ancestor(event.target, {
        className: "js-stream-comments-button"
      });
      const href = node.getAttribute("href") || "";
      const pathname = getPathname(href);
      const cluster = this.getClusterArticles(node);
      const label = node.getAttribute("data-story-label") || "";
      const uuid = getUuid(node);
      const refererUrl = window.location.href; // use current href as referrer before navigate away to next article
      const payload = {
        cluster,
        expandComments,
        label,
        pathname,
        refererUrl,
        uuid
      };
      this.dispatch(onClick, { type: "article", payload });
    }

    if (
      ancestor(target, { id: "viewer" }) &&
      !ancestor(target, { id: "content-viewer" })
    ) {
      // Close the viewer when we click on the overlay
      return this.dispatch(onClick, { type: "close" });
    }
  }

  /**
   * Get the cluster articles related from a clicked element.  The stream
   * will attempt to pull cluster items from the stream, ntk from ntk etc
   * but for things like feature bar where the are no related items we
   * return any other available data-wf-caas-uuid as a fallback.
   * @param {Element} node The clicked element
   */
  private getClusterArticles(node: HTMLElement) {
    const clusterSize = this.getClusterSize(node);
    if (clusterSize === 0) {
      return [];
    }

    const cluster =
      ancestor(node, { className: "drawer-fetch-target", tagName: "DIV" }) ||
      ancestor(node, { className: "stream-item", tagName: "LI" }) ||
      ancestor(node, { className: "tdv2-wafer-ntk-desktop", tagName: "DIV" }) ||
      ancestor(node, { className: "aside-sticky", tagName: "DIV" }) ||
      ancestor(node, { id: "Page" });

    if (!cluster) {
      return [];
    }

    let clickedArticleUuid =
      node.getAttribute("data-wf-caas-uuid") ||
      node.getAttribute("data-uuid") ||
      "";

    // todo remove this logic once we fix this in mono
    // if uuid is not found, look for stream item parent node
    if (!clickedArticleUuid) {
      const streamNode = ancestor(node, {
        className: "js-stream-content",
        tagName: "LI"
      });

      clickedArticleUuid =
        (streamNode &&
          (streamNode.getAttribute("data-wf-caas-uuid") ||
            streamNode.getAttribute("data-uuid"))) ||
        "";
    }

    // Remove the clicked article uuid from the cluster
    const articles = this.getArticles(cluster, clickedArticleUuid);

    // If we didn't get cluster items from here, check the next stream items.
    let next = cluster.nextElementSibling;
    while (Object.keys(articles).length < clusterSize && next) {
      Object.assign(
        articles,
        this.getArticles(next as HTMLElement, clickedArticleUuid)
      );
      next = next.nextElementSibling;
    }

    // If we're at the end of the stream, check the previous stream items.
    let prev = cluster.previousElementSibling;
    while (Object.keys(articles).length < clusterSize && prev) {
      Object.assign(
        articles,
        this.getArticles(prev as HTMLElement, clickedArticleUuid)
      );
      prev = prev.previousElementSibling;
    }

    // Return <clusterSize> number of articles from the uuid to article map
    this.clusters = Object.values(articles).slice(0, clusterSize);
    return this.clusters;
  }
}
