import "../polyfills/CustomEvent";
import { NavigationPayload, Plugin } from "../types/typings";

/**
 * This plugins emits an event when the viewer is closed.
 * One use case for this is that the base page needs to
 * know when the viewer is closed so it can rotate ads.
 */
export default class Events implements Plugin {
  public onViewerClosed() {
    dispatchEvent(new CustomEvent("viewerClosed"));
  }

  public onViewerOpened(payload: NavigationPayload) {
    const params = { detail: { uuid: payload.uuid } };
    dispatchEvent(new CustomEvent("viewerOpened", params));
  }

  public onContentChange(payload: NavigationPayload) {
    const params = { detail: { uuid: payload.uuid } };
    dispatchEvent(new CustomEvent("viewerContentChanged", params));
  }
}
