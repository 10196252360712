/* istanbul ignore file */

import { events } from "@vzmi/types-wafer";
import { Ads as Config } from "../types/configs";
import { Viewer as ViewerConfig } from "../types/configs";
import { OnClickEvent, Plugin } from "../types/typings";
import { getBeaconPath } from "../utils";

export default class Ads implements Plugin {
  private event: string;
  private config?: Config;
  private site!: string;

  constructor() {
    this.event = "homepage-viewer";
  }

  public init(viewerConfig: ViewerConfig) {
    if (viewerConfig) {
      this.site = viewerConfig.context.site || "fp";
    }
    if (window.adsConfig) {
      this.config = window.adsConfig;
    } else {
      const beacon = new Image();
      beacon.src = `/${getBeaconPath(this.site)}?error=no_ads_config`;
    }
    window.wafer.on("caas:article:init", this.displayAds.bind(this));
  }

  public onClick(event: OnClickEvent) {
    if (
      event.type === "slideshow" &&
      window.DARLA &&
      !window.DARLA.inProgress()
    ) {
      window.DARLA.event("slideshow");
    }
  }

  /**
   * When the user has adblock enabled, we will change the ids of the
   * divs to include the UBA prefix and the "Advertisement" text
   * per our agreement with AdBlock.
   */
  /* istanbul ignore next */
  public getAdBlockConfig(): Config | void {
    if (!this.config) {
      return;
    }

    const config: Config = JSON.parse(JSON.stringify(this.config));
    config.viewerPositionMeta.positions = config.viewerPositionMeta.positions.filter(
      position => {
        return (
          !position.id.startsWith("MON") &&
          !position.id.startsWith("MON2") &&
          !position.id.startsWith("FSRVY")
        );
      }
    );

    for (const position of config.viewerPositionMeta.positions) {
      const clean = document.getElementById(position.clean);
      const dest = document.getElementById(position.dest);

      if (clean && clean.parentNode && dest) {
        position.id = "UBA" + position.id;

        // Create the wrapper
        const base = document.createElement("div");
        base.id = "viewer-" + position.id + "-base";
        base.style.width = "100%";

        // Modify the ad config and targets
        position.clean = position.clean.replace("-", "-UBA");
        clean.id = position.clean;
        position.dest = position.dest.replace("-", "-UBA");
        dest.id = position.dest;

        // Wrap the original ad position
        clean.parentNode!.insertBefore(base, clean);
        base.appendChild(clean);

        // Then add the "Advertisement" span
        // TODO: intl
        const span = document.createElement("span");
        span.innerText = "Advertisement";
        span.style.color = "#787d82";
        span.style.display = "block";
        span.style.fontSize = "11px";
        span.style.marginBottom = "3px";
        span.style.marginLeft = "auto";
        span.style.marginRight = "auto";
        if (position.id.indexOf("LDRB") >= 0) {
          span.style.width = "728px";
        } else {
          span.style.width = "100%";
        }
        base.insertBefore(span, clean);
      }
    }

    return config;
  }

  public getConfig(): Config | void {
    if (document.cookie.includes("thamba=2")) {
      return this.getAdBlockConfig();
    }

    return this.config;
  }

  public onViewerClosed() {
    if (window.DARLA && window.DARLA.inProgress()) {
      window.DARLA.abort();
    }
  }

  public displayAds(event: events.caas.article.init.data) {
    const config = this.getConfig();
    if (!window.DARLA || !config) {
      return;
    }

    if (window.DARLA.inProgress()) {
      window.DARLA.abort();
    }

    const ps: string[] = [];
    let hostURL = window.location.protocol + "//" + window.location.host;
    if (window.location.pathname) {
      hostURL += window.location.pathname;
    }
    const adPositions = JSON.parse(
      JSON.stringify(config.viewerPositionMeta.positions)
    );
    for (const position of adPositions) {
      ps.push(position.id);
      if (!position.meta) {
        position.meta = {};
      }
      position.meta.hostURL = hostURL;
      window.DARLA.addPos(position);
    }
    const articleSiteAttributes =
      (event.meta.data.adMeta && event.meta.data.adMeta.site_attribute) || "";
    const bucketSiteAttributes =
      window.YAHOO && window.YAHOO.context && window.YAHOO.context.bucketId
        ? "Y-BUCKET=" + window.YAHOO.context.bucketId
        : "";
    const siteAttributes =
      config.viewerPositionMeta.siteAttributes +
      " " +
      articleSiteAttributes +
      " " +
      bucketSiteAttributes;

    // This event rotates all ad positions for client side nav
    window.DARLA.add(this.event, {
      name: this.event,
      ps: ps.join(","),
      ref: window.location.href,
      sa: siteAttributes,
      sp: event.meta.data.spaceId
    });

    // This event rotates a subset of positions for slideshow clicks
    window.DARLA.add("slideshow", {
      name: "slideshow",
      ps: config.viewerPositionMeta.slideshowPositions,
      ref: window.location.href,
      sa: siteAttributes,
      sp: event.meta.data.spaceId
    });

    // for adblock-plus users we dont have to enable AUTO event
    if (!document.cookie.includes("thamba=2")) {
      window.DARLA.stopAuto();
      window.DARLA.add("AUTO", {
        autoDDG: 1,
        autoIV: 1,
        autoMax: 25,
        autoRT: 10000,
        autoStart: 1,
        name: "AUTO",
        ps: ps.reduce((acc: any, position: string) => {
          acc[position] = {
            autoIV: 1,
            autoMax: 25,
            autoRT: 10000
          };
          return acc;
        }, {}),
        ref: window.location.href,
        sa: siteAttributes,
        sp: event.meta.data.spaceId
      });
      window.DARLA.startAuto();
    }
    window.DARLA.event(this.event);
  }
}
