import { events } from "@vzmi/types-wafer";
import { Comments as Config } from "../types/configs";
import { Viewer as ViewerConfig } from "../types/configs";
import {
  CanvassComments,
  CanvassCommentsParams,
  NavigationPayload,
  OnClickEvent,
  PartnerData,
  Plugin
} from "../types/typings";
import { sleep } from "../utils";

/**
 * After the modal has changed content, we want to show/update the community
 * bar with the canvass comments for that new article
 */
export default class Comments implements Plugin {
  private comments: Record<
    string,
    {
      id: string;
      instance: CanvassComments;
    }
  >;
  private config: Config;
  private expanded: boolean;
  private region: string;
  private lang: string;
  private site: string;

  constructor(config: Config) {
    this.comments = {};
    this.config = config;
    this.expanded = false;
    this.region = "US";
    this.lang = "en-US";
    this.site = "fp";
  }

  public init(viewerConfig: ViewerConfig) {
    if (viewerConfig) {
      this.region = viewerConfig.context.region;
      this.lang = viewerConfig.context.lang;
      this.site = viewerConfig.context.site;
    }

    const initCommentsOnView = this.initCommentsOnView.bind(this);
    window.wafer.on("caas:article:inview", initCommentsOnView);
    window.wafer.on("caas:article:init", initCommentsOnView);
  }

  public async onContentChange(payload: NavigationPayload) {
    this.expanded = payload.expandComments;
  }

  public async onClick(event: OnClickEvent) {
    if (event.type === "comments") {
      const uuid = event.payload!.uuid;

      const comments =
        this.comments[uuid] || (await this.initCommentsOnClick(uuid));
      if (comments) {
        // Allow comments to expand before scrolling to help calculation
        comments.instance.expand();
        await sleep(100);

        const element = document.getElementById(comments.id)!;
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  /**
   * When we close the viewer, destroy any comments instances we created
   */
  public onViewerDidClose() {
    // TODO: also destroy instances when navigating article to article
    Object.values(this.comments).forEach(comments => {
      comments.instance.destroy();
    });
    this.comments = {};
    this.expanded = false;
  }

  private getCommentsNodeId(uuid: string) {
    // @ts-ignore
    const state = window.wafer.base._state;
    for (const key of Object.getOwnPropertyNames(state)) {
      const value = state[key];
      if (value === uuid) {
        const node = document.querySelector(
          `[data-wf-state-caas-uuid="[state.${key}]"]`
        );
        if (node) {
          const commentsId = node.getAttribute("data-comments-id");
          return commentsId;
        }
      }
    }
  }

  private getRapidKeys(): Record<string, string> {
    if (!window.rapidInstance) {
      return {};
    }

    return window.rapidInstance.getRapidAttribute("keys");
  }

  private getRapidSpaceId(): string {
    if (!window.rapidInstance) {
      return "";
    }

    return window.rapidInstance.getRapidAttribute("keys");
  }

  private getCanvassCommentsParams(
    partnerData: PartnerData
  ): CanvassCommentsParams {
    const {
      hostedType,
      spaceId,
      title: contextDisplayText,
      url: contextUrl,
      uuid
    } = partnerData;
    const namespace =
      hostedType === "hosted" ? "yahoo_content" : "yahoo_offnet";
    const rapidKeys = this.getRapidKeys();
    const spaceid = spaceId || this.getRapidSpaceId();
    const { _rid: prid, site = "fp" } = rapidKeys;
    const { device } = this.config;
    const theme = this.site === "news" ? "redesign" : "";
    const themeContainerClasses = this.site === "news" ? "Ff(YahooSans)" : "";

    // TODO: Handle missing params
    return {
      // bucket,
      // rapid,
      context: uuid,
      contextDisplayText,
      contextUrl,
      device,
      lang: this.lang,
      namespace,
      prid,
      rapidKeys,
      region: this.region,
      site,
      spaceid,
      theme,
      themeContainerClasses,
      useRelativeHostName: true
    };
  }

  private initComments(
    uuid: string,
    id: string,
    params: CanvassCommentsParams
  ) {
    // Keep track of our instances so we can destroy them later on
    const instance = new window.YAHOO.CanvassComments(id, params);
    this.expanded = false; // reset expand state after every init
    return (this.comments[uuid] = {
      id,
      instance
    });
  }

  private initCommentsOnView(event: events.caas.article.init.data) {
    if (window.YAHOO) {
      const partnerData = event.meta.data as PartnerData;
      const uuid = partnerData.uuid;

      // don't add comments if comments is not allowed
      if (!partnerData.commentsAllowed) {
        return;
      }
      // We've already loaded comments for this article
      if (this.comments[uuid]) {
        return;
      }

      const id = event.elem.getAttribute("data-comments-id")!;
      const params = this.getCanvassCommentsParams(partnerData);
      params.expanded = this.expanded;
      this.initComments(uuid, id, params);
    }
  }

  private async initCommentsOnClick(uuid: string) {
    const [partnerData] = await window.wafer.caas.getData([uuid]);

    // don't add comments if comments is not allowed
    if (!partnerData.commentsAllowed) {
      return;
    }
    const params = this.getCanvassCommentsParams(partnerData);
    params.expanded = true;
    const id = this.getCommentsNodeId(uuid);

    if (id) {
      return this.initComments(uuid, id, params);
    }
  }
}
