import "./polyfills/Object.assign";
import "./polyfills/String.endsWith";

import Base from "./Base";
import Adobe from "./plugins/Adobe";
import Ads from "./plugins/Ads";
import AFT2 from "./plugins/AFT2";
import Beacon from "./plugins/Beacon";
import ClickHandler from "./plugins/ClickHandler";
import Comments from "./plugins/Comments";
import Comscore from "./plugins/Comscore";
import Content2Search from "./plugins/Content2Search";
import Event from "./plugins/Event";
import Modal from "./plugins/Modal";
import Nielsen from "./plugins/Nielsen";
import Rapid from "./plugins/Rapid";
import Router from "./plugins/Router";
import ScrollRestoration from "./plugins/ScrollRestoration";
import Sidekick from "./plugins/Sidekick";
import Sticky from "./plugins/Sticky";
import Title from "./plugins/Title";

const plugins = [
  new Ads(),
  new AFT2(),
  new Beacon(),
  new ClickHandler({
    clusterSize: 2,
    ntkClusterSize: 5,
    targetClassName: "js-content-viewer",
    targetTagName: "A"
  }),
  new Content2Search(),
  new Comments({
    device: "desktop"
  }),
  new Comscore({
    href: "https://sb.scorecardresearch.com/p"
  }),
  new Event(),
  new Modal(),
  new Sidekick({
    origin: "https://www.yahoo.com",
    pathname: "/caas/sidekick/sidekick"
  }),
  new Rapid({
    clusterSize: 2
  }),
  new Router(),
  new Sticky({
    pageStickyBottom: 30,
    pageStickyNode: "#Aside .aside-sticky",
    rightRailTop: 91,
    viewerContainerNode: "#content-articles-wrapper",
    viewerStickyBottom: 80,
    viewerStickyNode: "#viewer-aside .aside-sticky"
  }),
  new ScrollRestoration(),
  new Title(),
  new Adobe(),
  new Nielsen()
];

window.YAHOO.viewer = new Base(plugins);
