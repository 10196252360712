import { Event } from "./types/typings";

/**
 * @module events
 */

/**
 * Each plugin will be initialized when the viewer initializes
 */
export const init: Event = "init";

/**
 * Fired when a relevant click event occurs
 */
export const onClick: Event = "onClick";

/**
 * Fired before the DOM is updated with the new article, we'll need to fetch
 * the content that will be used to populate the modal, etc
 */
export const onContentWillChange: Event = "onContentWillChange";

/**
 * Fired when the modal is to change content, includes the markup in the payload
 */
export const onContentChange: Event = "onContentChange";

/**
 * Fired after the content changes.  At this point the DOM is available in the
 * modal so that attributes such as the title can be pulled from the modal
 */
export const onContentDidChange: Event = "onContentDidChange";

/**
 * Navigating from the index page to content will require the viewer to open.
 */
export const onViewerOpened: Event = "onViewerOpened";

/**
 * Navigating from a content page to the index page will require the viewer to
 * close.
 */
export const onViewerClosed: Event = "onViewerClosed";

/**
 * After the viewer has been closed some actions such as scroll restoration can occcur.
 */
export const onViewerDidClose: Event = "onViewerDidClose";
