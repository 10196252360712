import { AdobeAnalytics, Plugin } from "../types/typings";
import { get } from "../utils";

export default class Adobe implements Plugin {
  private adobeAnalytics?: AdobeAnalytics;

  public init() {
    // We need to check window.s.t specificailly since there
    // is a known window pollution with ybar setting window.s
    if (typeof get(window, "s.t") !== "function") {
      return;
    }

    this.adobeAnalytics = window.s;
    window.wafer.on("caas:article:inview", this.adobeAnalytics!.t);
  }

  public async onContentDidChange() {
    if (this.adobeAnalytics) {
      this.adobeAnalytics.prop53 = "Modal";
    }
  }

  public async onViewerClosed() {
    if (this.adobeAnalytics) {
      const device = this.adobeAnalytics.pna || "";
      const property = window.location.hostname.split(".", 1);
      this.adobeAnalytics.pageName = `net|${property}|${device}`;
      this.adobeAnalytics.prop53 = "Modal Close";
      this.adobeAnalytics.t();
      this.adobeAnalytics.clearVars();
    }
  }
}
